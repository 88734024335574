<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :modal-show="modal.show"
      :loading="loading"
    >
      <template #modal>
        <modal-confirmation
          title="Delete Item?"
          description="If you delete the item all of it's data will be lost!"
          text-confirm="Delete"
          text-cancel="Cancel"
          @cancel="modalCancel"
          @confirm="modalConfirm"
        />
      </template>

      <template #title>
        Item Settings
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">

          <carousel-view
            :images="pictureURLs"
            :selected.sync="imageSelected"
            :canDelete="false"
            @delete-image="deleteImage"
          />

          <p class="mb-4 text-sm text-content">
            *Landscape Pictures will display best
          </p>

          <!-- Item Info -->
          <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <text-input-component
              @save="triggerUpdate"
              :value.sync="item.name"
              :valid="item.name.length > 0"
              :disabled="projectPermissions === 'basic'"
              label="Name"
              :mode="projectPermissions !== 'basic' ? 'edit' : 'display'"
            />
            <dropdown-component
              label="Category"
              @save="triggerUpdate"
              :update:value="triggerUpdate"
              :value.sync='item.category'
              :items="categories"
              :mode="projectPermissions !== 'basic' ? 'edit' : 'display'"
            />
            <textarea-component
              @save="triggerUpdate"
              :value.sync="item.description"
              :valid="true"
              :disabled="projectPermissions === 'basic'"
              label="Description"
              :mode="projectPermissions !== 'basic' ? 'edit2' : 'display'"
              :rows="4"
            />
          </div>

          <!-- Actions -->
          <div
            v-if="projectPermissions !== 'basic'"
            class="w-full p-3 mb-4 rounded-lg bg-backfill"
          >
            <button-inline
              v-if="pictureURLs.length < 3"
              @click="takePicture"
              label="Pictures"
              value="Add"
              mode="edit"
              :disabled="false"
            />
            <button-inline
              v-if="pictureURLs.length > 1"
              @click="deleteImage"
              label="Current Picture"
              value="Delete"
              mode="edit"
              :disabled="false"
            />
            <input
              @change="addPicture" v-show='false'
              type="file" accept="image/*"
            />
          </div>

          <!-- Memory Items -->
          <div v-if="item.category === 'Memory'" class="w-full mb-4 ">
            <p class="ml-5 text-xl font-extrabold text-left text-content">Memory Notes</p>

            <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
              <textarea-component
                v-for="question in memoryQuestions"
                :key="question"
                @save="triggerUpdate"
                :value="item.memory_info[question]"
                @update:value="updateMemoryInfo(question, $event)"
                :disable="projectPermissions === 'basic'"
                :valid='true'
                :label='question'
                :rows="5"
                mode="edit2"
                class="mb-2"
              />

            </div>
          </div>

          <button-large
            v-if="projectPermissions !== 'basic'"
            @click="deleteButton"
            color="red"
            class="self-center"
          >
            Delete
          </button-large>
          <div class="h-10 opacity-0">space...</div>

        </div>
      </template>
    </interior-page-content-container>
</template>

<script>
/* eslint-disable prefer-destructuring */

import { mapItemFields } from '@/store/mappers';
import {
  ITEM_GET, ITEM_PUT, ITEM_DELETE,
} from '@/store/actions';
import {
  PROJECT_PERMISSIONS_CURRENT,
} from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import CarouselView from '@/components/shared/general/CarouselView.vue';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import ButtonInline from '@/components/shared/Buttons/ButtonInline.vue';
import DropdownComponent from '@/components/shared/inputs/DropdownComponent.vue';
import TextareaComponent from '../../components/shared/inputs/TextareaComponent.vue';

import * as memoryQuestions from '../../assets/json/memoryQuestions.json';

export default {
  name: 'ItemSettings',
  components: {
    InteriorPageContentContainer,
    CarouselView,
    ModalConfirmation,
    TextInputComponent,
    ButtonLarge,
    DropdownComponent,
    ButtonInline,
    TextareaComponent,
  },
  data() {
    return {
      categories: ['Moving', 'Sale', 'Storage', 'Junk', 'Donate', 'Memory'],
      itemValidity: {
        nameValid: true,
        categoryValid: true,
      },
      modal: {
        show: false,
        forDiscardChanges: true,
      },
      loading: true,
      saving: false,
      newPicture: null,
      pictures: [],
      imageSelected: 0,
    };
  },
  computed: {
    ...mapItemFields(['item']),
    projectPermissions() {
      return this.$store.getters[PROJECT_PERMISSIONS_CURRENT];
    },
    valid() {
      return this.itemValidity.nameValid && this.itemValidity.categoryValid;
    },
    picture() {
      if (this.newPicture) return URL.createObjectURL(this.newPicture);
      return this.item.picture.url;
    },
    pictureURLs() {
      const result = this.pictures.map((pic) => URL.createObjectURL(pic));
      return result;
    },
    defaultBackRoute() {
      return {
        name: 'Item',
        params: {
          project_id: this.$route.params.project_id,
          room_id: this.$route.params.room_id,
          item_id: this.$route.params.item_id,
        },
      };
    },
    memoryQuestions() {
      return memoryQuestions.memoryQuestions;
    },
  },
  watch: {
    item: {
      handler(newVal, oldVal) {
        if (oldVal.name !== null) {
          this.itemValidity.nameValid = newVal.name.length > 0;
          this.itemValidity.categoryValid = newVal.category.length > 0;
        }
      },
      deep: true,
    },
  },
  async created() {
    this.$store.dispatch(ITEM_GET, {
      projectId: this.$route.params.project_id,
      roomId: this.$route.params.room_id,
      itemId: this.$route.params.item_id,
    })
      .catch(() => this.$router.push({ name: 'PageNotFound' }))
      .then(async () => {
        this.pictures.push(await this.getImageFile(this.item.picture));
        if (this.item.picture_2) {
          this.pictures.push(await this.getImageFile(this.item.picture_2));
        }
        if (this.item.picture_3) {
          this.pictures.push(await this.getImageFile(this.item.picture_3));
        }
      })
      .finally(() => { this.loading = false; });
  },
  methods: {
    async getImageFile(img) {
      console.log(img);
      return fetch(
        img.url,
        {
          headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Origin: '*',
          }),
        },
      )
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          const file = new File([blob], `${img.name}.${img.extension}`);
          return file;
        });
    },
    triggerUpdate() {
      this.saving = true;
      this.put()
        .then(() => {
          this.saving = false;
        });
    },
    put() {
      const putItem = {
        name: this.item.name,
        category: this.item.category,
        description: this.item.description.length > 0 ? this.item.description : ' ',
        memory_info: this.item.memory_info,
      };
      if (this.pictures[0]) putItem.picture = this.pictures[0];
      if (this.pictures[1]) putItem.picture_2 = this.pictures[1];
      if (this.pictures[2]) putItem.picture_3 = this.pictures[2];

      return this.$store.dispatch(ITEM_PUT, {
        projectId: this.$route.params.project_id,
        roomId: this.$route.params.room_id,
        itemId: this.$route.params.item_id,
        item: putItem,
      })
        .catch(() => {
          console.log('The item was not updated');
        });
    },
    deleteButton() {
      this.modal.forDiscardChanges = false;
      this.modal.show = true;
    },
    addPicture(event) {
      // eslint-disable-next-line prefer-destructuring
      this.pictures.push(event.target.files[0]);
      this.triggerUpdate();
    },
    deleteImage() {
      this.pictures = this.pictures.filter((img, index) => index !== this.imageSelected);
      if (this.pictures.length >= this.imageSelected) this.imageSelected -= 1;
      this.triggerUpdate();
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },
    modalConfirm() {
      this.actionComplete = true;
      this.$store.dispatch(ITEM_DELETE, {
        projectId: this.$route.params.project_id,
        roomId: this.$route.params.room_id,
        itemId: this.$route.params.item_id,
      })
        .then(() => {
          this.$router.push({
            name: 'Room',
            params: {
              project_id: this.$route.params.project_id,
              room_id: this.$route.params.room_id,
            },
          });
        });
    },
    modalCancel() {
      this.modal.show = false;
    },
    backNav() {
      if (!this.saving) {
        this.$router.go(-1);
      }
    },
    updateMemoryInfo(key, value) {
      this.item.memory_info[key] = value;
    },
  },

};
</script>
